import {
  TradeType,
  TradeTypeInput,
} from "../brokerAdmin/tradeTracker/NewTradeFormItem";
import {
  CORERangePickerV2,
  RelativeRangeValue,
} from "../../COREDesignSystem/Form/CORERangePicker";
import { COREInput } from "../../COREDesignSystem/Form/COREInput";
import React from "react";
import { TestID } from "../../shared/testids/testids";
import {
  COREFilters,
  COREFiltersProps,
  FilterItems,
} from "../../COREDesignSystem/Form/COREFilters";
import { UseLocalStorageUntilLogoutProps } from "../../shared/customHoooks/useLocalStorageUntilLogout";
import moment from "moment";
import { AllAccreditationSelect } from "../../shared/select/AllAccreditationSelect";
import { MethodSelect } from "../../shared/select/MethodSelect";
import {
  CORESelectAPI,
  groupOptions,
} from "../../COREDesignSystem/Form/CORESelectAPI";
import { keysToSnake } from "../../shared/global";
import { ProjectType } from "../emissions/offsetComponents/offsets_helper";

const { Search } = COREInput;

export type TradeLogFilterItems = {
  search?: string;
  code?: string;
  accreditation?: string[];
  certificateProject?: number[];
  term?: string;
  tradeType?: TradeType[];
  method?: string[];
  deliveryTime?: RelativeRangeValue;
  tradeTime?: RelativeRangeValue;
  expiryTime?: RelativeRangeValue;
};

type TradeLogTableFilterProps = Pick<
  COREFiltersProps,
  "filterHeader" | "modalTitle" | "liteMode"
> & {
  testID: TestID;
  filterValues: TradeLogFilterItems;
  onChange:
    | ReturnType<UseLocalStorageUntilLogoutProps<TradeLogFilterItems>>[1]
    | ((value: TradeLogFilterItems) => void);
};

const TradeFiltersHOC = (filterKey: string[]) => {
  const component: React.FC<TradeLogTableFilterProps> = ({
    testID,
    filterHeader,
    modalTitle,
    filterValues,
    onChange,
    liteMode,
  }) => {
    const formattedFilterValues: TradeLogFilterItems =
      filterValues && Object.keys(filterValues).length > 0
        ? {
            ...filterValues,
            deliveryTime: filterValues["deliveryTime"]
              ? ({
                  ...filterValues["deliveryTime"],
                  from: moment(filterValues["deliveryTime"]?.from),
                  to: moment(filterValues["deliveryTime"]?.to),
                } as RelativeRangeValue)
              : undefined,
            tradeTime: filterValues["tradeTime"]
              ? ({
                  ...filterValues["tradeTime"],
                  from: moment(filterValues["tradeTime"]?.from),
                  to: moment(filterValues["tradeTime"]?.to),
                } as RelativeRangeValue)
              : undefined,
            expiryTime: filterValues["expiryTime"]
              ? ({
                  ...filterValues["expiryTime"],
                  from: moment(filterValues["expiryTime"]?.from),
                  to: moment(filterValues["expiryTime"]?.to),
                } as RelativeRangeValue)
              : undefined,
          }
        : {};

    const filterItems: FilterItems[] = [
      {
        label: `Search`,
        key: `search`,
        input: (
          <Search
            testID={`${testID}-search-box`}
            placeholder="Search by name"
            size="lg"
            widthSize="xl"
          />
        ),
        dividerAfter: true,
        widthSize: "xl",
      },
      {
        label: `Product type`,
        key: `accreditation`,
        input: (
          <AllAccreditationSelect
            testID={`${testID}-accreditation-selector`}
            placeholder="Select product type"
            size="lg"
            mode="multiple"
          />
        ),
        widthSize: "md",
      },
      {
        label: `Term`,
        key: `term`,
        input: (
          <Search
            testID={`${testID}-search-term-box`}
            placeholder="Search by term"
            size="lg"
            widthSize="xl"
          />
        ),
        widthSize: "md",
      },
      {
        label: `Method`,
        key: `method`,
        input: (
          <MethodSelect
            testID={`${testID}-method-selector`}
            mode="multiple"
            transform={(data) => groupOptions(data, "name", "name", "name")}
            size="lg"
            placeholder="Select method"
          />
        ),
        widthSize: "md",
      },
      {
        label: `Traded date range`,
        key: `tradeTime`,
        input: (
          <CORERangePickerV2
            testID={`${testID}-traded-date-range`}
            size="lg"
            placeholder={["Traded date from", "Traded date to"]}
            allowClear
          />
        ),
        widthSize: "xl",
      },
      {
        label: `Delivery date range`,
        key: `deliveryTime`,
        input: (
          <CORERangePickerV2
            testID={`${testID}-delivery-date-range`}
            size="lg"
            placeholder={["Delivery date from", "Delivery date to"]}
            allowClear
          />
        ),
        widthSize: "xl",
      },
      {
        label: `Trade type`,
        key: `tradeType`,
        input: (
          <TradeTypeInput
            testID={`${testID}-trade-type-selector`}
            placeholder="Select trade type"
            size="lg"
            mode="multiple"
          />
        ),
        widthSize: "md",
      },
      {
        label: `Project`,
        key: `certificateProject`,
        input: (
          <CORESelectAPI
            testID={`${testID}-project-selector`}
            placeholder="Select project"
            widthSize="xl"
            size="lg"
            mode="multiple"
            allowClear
            endpoint="certificateProjectGreenProjects"
            params={keysToSnake({
              select: `*`,
            })}
            transform={(data: ProjectType[]) => {
              return data.flatMap((d) => {
                return d
                  ? {
                      label: `${d.project_identifier} - ${d.name}`,
                      value: d.id,
                    }
                  : [];
              });
            }}
          />
        ),
        widthSize: "md",
      },
      {
        label: `Search`,
        key: `code`,
        input: (
          <Search
            testID={`${testID}-code-search`}
            placeholder="Search by trade code"
            size="lg"
            widthSize="xl"
          />
        ),
        dividerAfter: true,
        widthSize: "xl",
      },
      {
        label: `Expiry date range`,
        key: `expiryTime`,
        input: (
          <CORERangePickerV2
            testID={`${testID}-expiry-date-range`}
            size="lg"
            placeholder={["Expiry date from", "Expiry date to"]}
            allowClear
          />
        ),
        widthSize: "xl",
      },
    ];

    const matchedFilterItems: FilterItems[] = [];
    filterKey.forEach((key) => {
      const matchingFilterItem = filterItems.find(
        (filterItem) => filterItem.key === key
      );
      if (matchingFilterItem) {
        matchedFilterItems.push(matchingFilterItem);
      }
    });

    return (
      <COREFilters
        testID={testID}
        items={matchedFilterItems}
        value={formattedFilterValues}
        modalTitle={modalTitle}
        filterHeader={filterHeader}
        liteMode={liteMode}
        onChange={(v) => {
          onChange(v);
        }}
      />
    );
  };

  return component;
};

export const TradeLogFilter = TradeFiltersHOC([
  "search",
  "accreditation",
  "term",
  "method",
  "certificateProject",
  "tradeType",
  "tradeTime",
  "deliveryTime",
]);
export const TradeTickerFilter = TradeFiltersHOC([
  "code",
  "tradeTime",
  "accreditation",
  "term",
  "tradeType",
  "method",
  "certificateProject",
  "deliveryTime",
  "expiryTime",
]);
