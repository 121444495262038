import { useUserConfigs } from "./useUserConfig";
import { Widgets } from "../../modules/dashboards/dashboardsComponent/widgets/reactGridLayout/ReactGridLayoutFunction";
import { FeatureFlag, useFeatureFlags } from "./useFeatureFlags";
import { getNewsAndAlertsPermission } from "../../modules/admin/contentManagement/newsAndAlerts/newsAndAlertsHelper";

type ComponentName = keyof typeof Widgets;
type DashBoardTemplate = {
  label: string;
  componentName: ComponentName;
  group: "Market prices" | "Daily curves" | "Tools" | "Insights";
};

type DashboardTemplateResult = {
  label: string;
  value: DashBoardTemplate["componentName"];
};

type DashboardTemplateOptions = {
  label: string;
  options: DashboardTemplateResult[];
};

const MarketPricesSection = "Market prices";
const DailyCurvesSection = "Daily curves";

const dailyCurves = (features: FeatureFlag[]): DashBoardTemplate[] => {
  const items: DashBoardTemplate[] = [];
  if (
    features.includes("core.daily-curves.read") &&
    features.some((feature) =>
      ["daily-curves-carbon", "daily-curves-environmental"].includes(feature)
    )
  ) {
    items.push(
      {
        label: `Market commentary`,
        componentName: "MarketCommentaryWidget",
        group: DailyCurvesSection,
      },
      {
        label: `Daily curve (table)`,
        componentName: "DailyCurveTableWidget",
        group: DailyCurvesSection,
      }
    );
  }
  return items;
};

const marketPrices = (features: FeatureFlag[]): DashBoardTemplate[] => {
  const marketPriceFeatures: FeatureFlag[] = [
    "market-prices-carbon",
    "market-prices-environmental",
    "market-prices-rehub",
    "market-prices-electricity",
  ];

  const items: DashBoardTemplate[] = [];
  if (features.includes(marketPriceFeatures[0])) {
    items.push({
      label: `Carbon certificates ${MarketPricesSection}`,
      componentName: "CarbonCertificatesWidget",
      group: MarketPricesSection,
    });
  }

  if (features.includes(marketPriceFeatures[1])) {
    items.push({
      label: `Environmental certificates ${MarketPricesSection}`,
      componentName: "EnviroLiveMarketScreenWidget",
      group: MarketPricesSection,
    });
  }

  if (features.includes(marketPriceFeatures[3])) {
    items.push({
      label: `Electricity shapes - ${MarketPricesSection}`,
      componentName: "REHUBLiveMarketScreenWidget",
      group: MarketPricesSection,
    });
  }
  if (features.some((feature) => marketPriceFeatures.includes(feature))) {
    items.push(
      {
        label: "Trade ticker",
        componentName: "TradeTicker",
        group: MarketPricesSection,
      },
      {
        label: "Product watch List",
        componentName: "LivePrice",
        group: MarketPricesSection,
      }
    );
  }
  if (
    features.includes(marketPriceFeatures[0]) &&
    (features.includes("core.product.accu.read") ||
      features.includes("core.product.lgc.read"))
  ) {
    items.push({
      label: "Price history chart (OHLC)",
      componentName: "OHLCPriceHistoryWidget",
      group: MarketPricesSection,
    });
  }

  return items;
};

const tools = (features: FeatureFlag[]): DashBoardTemplate[] => {
  const items: DashBoardTemplate[] = [
    {
      componentName: "AemoPriceMapOverview",
      label: "Live NEM map",
      group: "Tools",
    },
  ];

  if (features.includes("aemo-market-notices")) {
    items.push({
      componentName: "AemoMarketNotices",
      label: "Aemo market notices",
      group: "Tools",
    });
  }
  if (features.includes("project-marketplace")) {
    items.push({
      componentName: "ProjectFinder",
      label: "Project finder",
      group: "Tools",
    });
  }

  return items;
};

const insights = (features: FeatureFlag[]): DashBoardTemplate[] => {
  const items: DashBoardTemplate[] = [];

  if (features.includes("articles-and-updates")) {
    items.push({
      componentName: "ArticlesAndUpdatesWidget",
      label: "Articles and updates",
      group: "Insights",
    });
  }

  if (
    features.includes("research-and-analysis") &&
    features.includes("bespoke-reports")
  ) {
    items.push({
      componentName: "ResearchAndAnalysisWidget",
      label: "Research and analysis",
      group: "Insights",
    });
  }

  if (getNewsAndAlertsPermission(features)) {
    items.push({
      componentName: "NewsAndAlertsWidget",
      label: "News and alerts",
      group: "Insights",
    });
  }

  return items;
};

export const useDashboardTemplate = (): DashBoardTemplate[] => {
  const { features } = useFeatureFlags();
  const { configs: userConfigAll } = useUserConfigs<{ title: string }>("all");
  const chartBuilderConfig = userConfigAll
    .filter((c) => c.data.type === "chart-builder")
    .map((c, index) => ({
      componentName: `ChartBuilder-${index}`,
      group: "Chart builder",
      props: {
        config: { id: c.data.id },
      },
      label: c.data.config.title,
    }));

  return [
    ...dailyCurves(features),
    ...marketPrices(features),
    ...tools(features),
    ...insights(features),
    ...chartBuilderConfig,
  ].filter(Boolean) as DashBoardTemplate[];
};

const generateDashboardTemplateOptions = (
  array: DashBoardTemplate[],
  index = 0,
  result: { [key: string]: DashboardTemplateResult[] } = {}
): DashboardTemplateOptions[] => {
  if (index === array.length) {
    return Object.entries(result).map(([label, options]) => ({
      label,
      options,
    }));
  }

  const { label, componentName, group } = array[index];
  result[group] = result[group] || [];
  result[group].push({ label, value: componentName });

  return generateDashboardTemplateOptions(array, index + 1, result);
};

export const useDashboardTemplateOptions = () =>
  generateDashboardTemplateOptions(useDashboardTemplate());
