import React from "react";
import { TestID } from "../../../../shared/testids/testids";
import { CORECard } from "../../../../COREDesignSystem/Content/CORECard";
import { CORETag } from "../../../../COREDesignSystem/Content/CORETag";
import DefaultImagePlaceholder from "../../../../assets/img/certificate-project-default-banner.svg";
import { COREHeading } from "../../../../COREDesignSystem/Typography/COREHeading";
import { COREBody } from "../../../../COREDesignSystem/Typography/COREBody";
import { CORETagList } from "../../../../COREDesignSystem/Content/CORETagList";
import {
  grey120,
  grey140,
} from "../../../../COREDesignSystem/Content/COREColour";
import { Col, Row, Space } from "antd";
import { displayRelativeDate } from "../../../../shared/date/DateTime";
import { insightCategoryOptions } from "../../../../shared/select/InsightCategorySelect";
import { insightTypeOptions } from "../../../../shared/select/InsightTypeSelect";
import { Insights } from "../../../../openapi-typescript/common/insights";
import moment from "moment";
import { COREButton } from "../../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const InsightsCard: React.FC<{ insights: Insights; testID: TestID }> = ({
  insights: { fieldData },
  testID,
}) => {
  const insightsURL = `https://coremarkets.co/insights/${fieldData.slug}?utm_source=platform&utm_medium=articles-and-updates`;
  return (
    <>
      <CORECard
        badge={
          <Space direction="vertical">
            <CORETag
              label={
                insightTypeOptions.find((i) => i.value === fieldData.type)
                  ?.label
              }
              type="colourful"
              colour="indigo100"
              tagStyle="solid"
              testID={`${testID}-badge`}
            />
          </Space>
        }
        elevation="none"
        lite
        bordered={false}
        hoverable={false}
        coverUrl={{
          onClick: () => window.open(insightsURL),
          image: fieldData["header-image"]?.url ?? "",
          imagePlaceholder: DefaultImagePlaceholder,
        }}
        testID={`${testID}-card`}
      >
        <COREHeading level={5} testID={`${testID}-heading`}>
          {fieldData.name}
        </COREHeading>
        <COREBody ellipsis={{ rows: 4 }} type="p3">
          {fieldData.introduction}
        </COREBody>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <CORETagList truncateAfter={3} showTooltip>
              {fieldData.categories?.map((category: string) => (
                <CORETag
                  label={
                    insightCategoryOptions.find((i) => i.value === category)
                      ?.label ?? ""
                  }
                  type="basic"
                  key={category}
                />
              ))}
            </CORETagList>
          </Col>
          <Col span={24}>
            <COREBody type="p5" color={grey120}>
              Published:{" "}
              {fieldData.date
                ? displayRelativeDate(moment(fieldData.date))
                : ""}
            </COREBody>
          </Col>
        </Row>
        <COREButton
          color={grey140}
          key="read more"
          target="_blank"
          href={insightsURL}
          type="link"
          size="sm"
          icon={
            <COREIcon icon={icon({ name: "arrow-up-right-from-square" })} />
          }
        >
          Read more
        </COREButton>
      </CORECard>
    </>
  );
};
