import React, { useState } from "react";
import { useDeepCompareEffect } from "react-use";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../../shared/testids/testids";
import { capitalizeFirstLetter, volumeRender } from "../../../shared/global";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { Col, Row, Space } from "antd";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import "./TradeTicker.less";
import { projectMarketplaceDetailsLink } from "../projectMarketplace/projectMarketplaceHelper";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import {
  TradeLogItem,
  useTradeLogsWithDatePaginate,
} from "../tradeLog/useTradeLog";
import { priceFormatter } from "../../../shared/globals";
import { displayRelativeDate, formatDate } from "../../../shared/date/DateTime";
import moment, { Moment } from "moment";
import classNames from "classnames";
import { COREInfiniteScroll } from "../../../COREDesignSystem/Content/COREInfiniteScroll";
import { convertMomentToMarketTimezone } from "../../../shared/date/DateTime";
import { NonEmptyRange, createRange } from "../../../shared/date/ranges";
import { CORETooltip } from "../../../COREDesignSystem/Overlay/CORETooltip";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";
import { UseLocalStorageUntilLogoutProps } from "../../../shared/customHoooks/useLocalStorageUntilLogout";
import { TradeLogFilterItems, TradeTickerFilter } from "../TradeFiltersHOC";

const NoMoreTrade: React.FC<{ testID: TestID; isHaveData: boolean }> = ({
  testID,
  isHaveData,
}) => {
  return (
    <>
      {isHaveData && <COREDivider dashed={true} />}
      <TestIDWrapper
        className={"showing-all"}
        testID={`${testID}-widgets-showing-all` as TestID}
      >
        <COREBody type="p3" strong={true} marginBottom={false}>
          Showing all
        </COREBody>
      </TestIDWrapper>
    </>
  );
};

const TradeTooltipDetail: React.FC<{
  testID: TestID;
  tradeLogItem: TradeLogItem;
}> = ({ testID, tradeLogItem }) => {
  const dateFormatted = "MMM D, YYYY [at] HH:mm";
  const tradeTypeName =
    tradeLogItem.tradeType === "firm"
      ? "Standard"
      : capitalizeFirstLetter(
          tradeLogItem.tradeType ? tradeLogItem.tradeType : ""
        );
  const priceLabel =
    tradeLogItem.tradeType === "option" ? "Price (premium)" : "Price";
  const nonEmptyDate = (date: Moment) => {
    if (date) return convertMomentToMarketTimezone(date).format("ll");
    return "-";
  };
  const expireDate =
    tradeLogItem.tradeType === "option"
      ? nonEmptyDate(tradeLogItem.exerciseExpiry)
      : nonEmptyDate(tradeLogItem.expiryTime);

  return (
    <Row gutter={[0, 15]}>
      <Col span={24}>
        <Space direction="vertical" size={2}>
          <CORETypographyInput
            type="label"
            testID={`${testID}-trade-date-label`}
          >
            Trade date
          </CORETypographyInput>
          <CORETypographyInput
            type="default"
            testID={`${testID}-trade-date-value`}
          >
            {convertMomentToMarketTimezone(tradeLogItem.tradeTime).format(
              dateFormatted
            )}
          </CORETypographyInput>
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={2}>
          <CORETypographyInput type="label" testID={`${testID}-volume-label`}>
            Volume
          </CORETypographyInput>
          <CORETypographyInput type="default" testID={`${testID}-volume-value`}>
            {volumeRender(tradeLogItem.volume)}
          </CORETypographyInput>
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={2}>
          <CORETypographyInput
            type="label"
            testID={`${testID}-${priceLabel}-label`}
          >
            {priceLabel}
          </CORETypographyInput>
          <CORETypographyInput
            type="default"
            testID={`${testID}-${priceLabel}-value`}
          >
            {tradeLogItem.price
              ? `$${priceFormatter(tradeLogItem.price, false)}`
              : "-"}
          </CORETypographyInput>
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={2}>
          <CORETypographyInput
            type="label"
            testID={`${testID}-trade-type-label`}
          >
            Trade type
          </CORETypographyInput>
          <CORETypographyInput
            type="default"
            testID={`${testID}-trade-type-value`}
          >
            {tradeTypeName}
            {tradeLogItem.optionType && " - "}
            {capitalizeFirstLetter(
              tradeLogItem.optionType ? tradeLogItem.optionType : ""
            )}
          </CORETypographyInput>
        </Space>
      </Col>
      {tradeLogItem.tradeType === "option" && (
        <>
          <Col span={24}>
            <Space direction="vertical" size={2}>
              <CORETypographyInput
                type="label"
                testID={`${testID}-strike-price-label`}
              >
                Strike price
              </CORETypographyInput>
              <CORETypographyInput
                type="default"
                testID={`${testID}-strike-price-value`}
              >
                {tradeLogItem.strikePrice
                  ? `$${priceFormatter(tradeLogItem.strikePrice, false)}`
                  : "-"}
              </CORETypographyInput>
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" size={2}>
              <CORETypographyInput
                type="label"
                testID={`${testID}-volatility-label`}
              >
                Volatility
              </CORETypographyInput>
              <CORETypographyInput
                type="default"
                testID={`${testID}-volatility-value`}
              >
                {tradeLogItem.volatility ? tradeLogItem.volatility : "-"}
              </CORETypographyInput>
            </Space>
          </Col>
        </>
      )}
      <Col span={24}>
        <Space direction="vertical" size={2}>
          <Space direction="vertical" size={2}>
            <CORETypographyInput
              type="label"
              testID={`${testID}-delivery-date-label`}
            >
              Delivery date
            </CORETypographyInput>
            <CORETypographyInput
              type="default"
              testID={`${testID}-delivery-date-value`}
            >
              {tradeLogItem.deliveryTime
                ? convertMomentToMarketTimezone(
                    tradeLogItem.deliveryTime
                  ).format("ll")
                : "-"}
            </CORETypographyInput>
          </Space>
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={2}>
          <CORETypographyInput
            type="label"
            testID={`${testID}-expiry-date-label`}
          >
            Expiry date
          </CORETypographyInput>
          <CORETypographyInput
            type="default"
            testID={`${testID}-expiry-date-value`}
          >
            {expireDate}
          </CORETypographyInput>
        </Space>
      </Col>
    </Row>
  );
};

const TradeTickerContent: React.FC<{
  testID: TestID;
  filterValue: TradeLogFilterItems;
}> = ({ testID, filterValue }) => {
  const [loadedRange, setLoadedRange] = useState<NonEmptyRange>();

  useDeepCompareEffect(() => {
    setLoadedRange(undefined);
  }, [filterValue]);

  const { updatable, loading, sync, hasMore } = useTradeLogsWithDatePaginate(
    filterValue,
    undefined,
    loadedRange,
    "week"
  );
  let currentDisplayDate: string | null = null;
  const isHaveData = updatable.length > 0;

  return (
    <COREInfiniteScroll
      loadMore={() => {
        if (!hasMore) return;
        if (!loadedRange) {
          const latestRecord = updatable[updatable.length - 1];
          const latestRecordDate = convertMomentToMarketTimezone(
            moment(latestRecord.data?.tradeTime)
          );
          setLoadedRange(createRange(latestRecordDate, latestRecordDate));
        } else {
          setLoadedRange(
            createRange(
              loadedRange.from.subtract(1, "week"),
              loadedRange.to,
              loadedRange.bounds
            )
          );
        }
      }}
      loading={loading}
      sync={sync}
      hasMore={hasMore}
      contentRange={updatable.length}
      noMoreRender={<NoMoreTrade testID={testID} isHaveData={isHaveData} />}
      emptyRender={<NoMoreTrade testID={testID} isHaveData={isHaveData} />}
      useWindow={false}
      className={"infinite-scroll"}
      loader={<CORELoading testID={`${testID}-loading`} delay={0} />}
    >
      <div className={classNames("pr-12", "trade-ticker-block")}>
        {updatable.map((item, index) => {
          if (!item.data) return null;

          const tradeLogItem = item.data;
          const isYesterdayOrToday = !moment(
            tradeLogItem?.tradeTime
          ).isSameOrBefore(moment().subtract(2, "day"), "day");
          const currentRelativeDate = isYesterdayOrToday
            ? capitalizeFirstLetter(
                displayRelativeDate(moment(tradeLogItem?.tradeTime), ["day"], {
                  numeric: "auto",
                })
              )
            : formatDate(moment(tradeLogItem?.tradeTime).toDate(), {
                dateStyle: "medium",
              });
          const isFirstTradeForEachDate =
            currentDisplayDate === null ||
            currentDisplayDate !== currentRelativeDate;

          if (isFirstTradeForEachDate) {
            currentDisplayDate = currentRelativeDate;
          }
          return (
            <React.Fragment key={`${item.data?.code}-${index}`}>
              <div className={"full-block"}>
                <COREDivider
                  dashed={!isFirstTradeForEachDate}
                  orientation="left"
                  testID={`${testID}-divider-date`}
                  space={isFirstTradeForEachDate ? "lg" : "sm"}
                  noOrientationMargin={isFirstTradeForEachDate}
                  textColor="white"
                  labelColor="grey140"
                >
                  {isFirstTradeForEachDate && currentDisplayDate}
                </COREDivider>
              </div>
              <CORETooltip
                title={
                  <TestIDWrapper testID={`${testID}-product-code-title`}>
                    {tradeLogItem?.code}
                  </TestIDWrapper>
                }
                message={
                  <TradeTooltipDetail
                    tradeLogItem={tradeLogItem}
                    testID={testID}
                  />
                }
                position="left"
                testID={`${testID}-${tradeLogItem?.code}-tooltip`}
                className={"trade-ticker-content-block"}
              >
                <div className={"trade-ticker-details"}>
                  <COREBody
                    type={"p3"}
                    strong={true}
                    marginBottom={false}
                    testID={`${testID}-${tradeLogItem?.code}`}
                  >
                    {tradeLogItem?.code}
                  </COREBody>
                  <Space className={"price-block"}>
                    <COREBody
                      type={"p2"}
                      marginBottom={false}
                      testID={`${testID}-${tradeLogItem?.code}-volume`}
                    >
                      {tradeLogItem?.volume &&
                        volumeRender(tradeLogItem.volume)}
                    </COREBody>
                    {tradeLogItem?.price && (
                      <COREBody
                        type={"p2"}
                        marginBottom={false}
                        testID={`${testID}-${tradeLogItem?.code}-price`}
                      >{`@ $${priceFormatter(
                        tradeLogItem.price,
                        false
                      )}`}</COREBody>
                    )}
                  </Space>
                </div>
                <div className={"full-block"}>
                  {tradeLogItem?.certificateProject && (
                    <COREButton
                      type="link"
                      target="_blank"
                      size="sm"
                      href={projectMarketplaceDetailsLink(
                        tradeLogItem.certificateProject
                      )}
                    >
                      View project
                    </COREButton>
                  )}
                </div>
              </CORETooltip>
            </React.Fragment>
          );
        })}
      </div>
    </COREInfiniteScroll>
  );
};

export const TradeTicker: React.FC<{
  heading?: React.ReactNode;
  testID: TestID;
  filterValues: TradeLogFilterItems;
  onChange:
    | ReturnType<UseLocalStorageUntilLogoutProps<TradeLogFilterItems>>[1]
    | ((value: TradeLogFilterItems) => void);
}> = ({ filterValues, heading = null, testID, onChange }) => {
  return (
    <div className={"trade-ticker-layout"}>
      <div>
        {heading}
        <TradeTickerFilter
          testID={generateTestId("tradeticker", "trade-ticker")}
          filterHeader="Filter trades"
          modalTitle="Trade timeline filters"
          onChange={onChange}
          filterValues={filterValues}
          liteMode
        />
      </div>
      <div className={"trade-ticker-component"}>
        <TradeTickerContent
          filterValue={filterValues}
          testID={`${testID}-content`}
        />
      </div>
    </div>
  );
};
