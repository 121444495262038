import React from "react";
import { Col, Row, Tabs } from "antd";
import { COREContainer } from "../../../COREDesignSystem/Layout/COREContainer";
import { generateTestId } from "../../../shared/testids/testids";
import { EodEditor } from "./EodEditor";
import moment from "moment";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { CarbonTableWithFilter } from "./CarbonTableWithFilter";
import { CORETabs } from "../../../COREDesignSystem/Content/CORETabs";
import "./CertificateCurve.less";
import { BrokerChart } from "./BrokerChart";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import {
  CertificateCurveData,
  DailyCurvesWithSnapshot,
} from "../CarbonDailyCurvePageWrapper";
import {
  daily_curve_methods as MethodTypeAdmin,
  daily_curves as DailyCurves,
} from "../../../openapi-typescript/admin";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { DailyCurveTableFilterType } from "../widget/SetupDailyCurveWidget";
import { CarbonTableProps } from "./CarbonTable";
import { useLocalStorageUntilLogout } from "../../../shared/customHoooks/useLocalStorageUntilLogout";
import { spacing } from "../../../shared/global";

const { TabPane } = Tabs;

export type CertificateCurveProps = {
  isPublished: boolean;
  curveDate: moment.Moment;
  certificateCurve: CertificateCurveData;
  isLoading?: boolean;
};

type TradableProductData = {
  id?: number;
  shortName: string;
};

export type TabData = {
  name: string;
  tradableProduct: TradableProductData[];
};

export type MethodType = MethodTypeAdmin;

type BrokerChartContainerProps = {
  productType: CertificateCurveData["productType"];
  productTypeName: CertificateCurveData["productTypeName"];
  dailyCurves: DailyCurvesWithSnapshot[];
  date: moment.Moment;
};

const BrokerChartContainer: React.FC<BrokerChartContainerProps> = ({
  productType,
  productTypeName,
  dailyCurves,
  date,
}) => {
  const tabDatas: TabData[] = dailyCurves.reduce(
    (prev: TabData[], cur: DailyCurves) => {
      // add Wholesale
      if (cur.hasBaseCurve) {
        const existTabData = prev.find((p) => p.name === "Wholesale");
        if (existTabData === undefined) {
          prev.push({
            name: "Wholesale",
            tradableProduct: [
              {
                id: cur.tradableProductId,
                shortName: cur.shortName,
              },
            ],
          });
        } else {
          prev
            .find((p) => p.name === "Wholesale")
            ?.tradableProduct.push({
              id: cur.tradableProductId,
              shortName: cur.shortName,
            });
        }
      }
      // add method curves
      if (
        cur.certificateProductPremiumCurves &&
        cur.certificateProductPremiumCurves.length > 0
      ) {
        cur.certificateProductPremiumCurves.forEach((c) => {
          const existTabData = prev.find(
            (p) => p.name === c.certificate_project_type_name
          );
          if (existTabData === undefined) {
            prev.push({
              name: c.certificate_project_type_name,
              tradableProduct: [
                {
                  id: c.tradable_product.id,
                  shortName: c.tradable_product.short_name,
                },
              ],
            });
          } else {
            existTabData.tradableProduct.push({
              id: c.tradable_product.id,
              shortName: c.tradable_product.short_name,
            });
          }
        });
      }
      return prev;
    },
    []
  );
  if (tabDatas.length === 1) {
    return (
      <BrokerChart
        date={date}
        data={tabDatas[0]}
        productType={productType}
        productTypeName={productTypeName}
        displayByPanel={false}
        empty={
          <COREEmpty
            description={"No curve data available"}
            hint={
              "Try reloading the page. If the problem persists, please contact CORE Markets support."
            }
            testID={generateTestId(
              "carbondailycurve",
              `${productType}-no-data`
            )}
          />
        }
      />
    );
  }
  return (
    <COREContainer
      testID={generateTestId("carbondailycurve", `${productType}-end-of-day`)}
      header={`${productTypeName} - End of day`}
      noScroll={true}
    >
      {tabDatas.length === 0 ? (
        <COREEmpty
          description={"No Data"}
          testID={generateTestId("carbondailycurve", `${productType}-no-data`)}
        />
      ) : (
        <CORETabs
          testID={generateTestId(
            "carbondailycurve",
            `${productType}-end-of-day-tabs`
          )}
          className={"carbon-chart-tabs"}
        >
          {tabDatas.map((tabData) => (
            <TabPane tab={tabData.name} key={tabData.name}>
              <BrokerChart
                date={date}
                data={tabData}
                productType={productType}
                productTypeName={productTypeName}
                displayByPanel={true}
                empty={
                  <COREEmpty
                    description={"No curve data available"}
                    hint={
                      "Try reloading the page. If the problem persists, please contact CORE Markets support."
                    }
                    testID={generateTestId(
                      "carbondailycurve",
                      `${productType}-no-data`
                    )}
                  />
                }
              />
            </TabPane>
          ))}
        </CORETabs>
      )}
    </COREContainer>
  );
};

const CarbonTableWithStoreFilter: React.FC<CarbonTableProps> = ({
  productType,
  methodTypes,
  testId,
  ...props
}) => {
  const [filterValues, setFilterValues] = useLocalStorageUntilLogout<{
    [productType: string]: DailyCurveTableFilterType | null | undefined;
  } | null>({
    key: "dailycurvesCarbonTable",
    initialValue: {},
  });

  const onChange = (filter?: DailyCurveTableFilterType | null | undefined) => {
    setFilterValues((prev) => {
      return {
        ...prev,
        [`${productType}`]: filter,
      };
    });
  };

  return (
    <CarbonTableWithFilter
      productType={productType}
      methodTypes={methodTypes}
      initFilters={
        filterValues && productType ? filterValues[productType] : null
      }
      onFilterChange={onChange}
      testID={testId}
      {...props}
    />
  );
};

export const CertificateCurve: React.FC<CertificateCurveProps> = ({
  curveDate,
  isPublished,
  certificateCurve: {
    currency,
    dailyCurves,
    productType,
    productTypeName,
    productCommentary,
  },
  isLoading = false,
}) => {
  const methodTypes: DailyCurvesWithSnapshot["certificateProductPremiumCurves"] =
    dailyCurves.find(
      (d) =>
        d.certificateProductPremiumCurves &&
        d.certificateProductPremiumCurves.length !== 0
    )?.certificateProductPremiumCurves;

  const hasMethods: boolean = Array.isArray(methodTypes);
  const shouldBeSingleColumn =
    methodTypes !== undefined && methodTypes?.length > 2;
  const dailyCurve = dailyCurves?.find((d) => d) || { hasBaseCurve: false };
  const hasBaseCurve: boolean = dailyCurve?.hasBaseCurve;
  return (
    <Row gutter={[spacing.sm, spacing.sm]}>
      <Col
        xxl={shouldBeSingleColumn ? 24 : 14}
        xl={shouldBeSingleColumn ? 24 : 13}
        lg={24}
        md={24}
        sm={24}
        xs={24}
      >
        <CORELoading loading={isLoading} size={"lg"}>
          <COREContainer
            testID={generateTestId("carbondailycurve", `${productType}`)}
            header={`${productTypeName} - Daily update`}
            noScroll={true}
          >
            {!hasBaseCurve && !hasMethods ? (
              <COREEmpty
                description={"No Data"}
                testID={generateTestId(
                  "carbondailycurve",
                  `${productType}-no-data`
                )}
              />
            ) : (
              <>
                <EodEditor
                  key={`carbonDailyCurve${curveDate}`}
                  certificate={productType}
                  date={curveDate}
                  commentaryData={{
                    certificate: productType,
                    commentary: productCommentary?.commentary ?? "",
                  }}
                  isPublished={isPublished}
                  testId={generateTestId(
                    "carbondailycurve",
                    `${productTypeName}-commentary-text-content`
                  )}
                />
                <COREDivider space="lg" />
                <CarbonTableWithStoreFilter
                  testId={generateTestId(
                    "carbondailycurve",
                    `${productTypeName}-carbon`
                  )}
                  isPublished={isPublished}
                  dailyCurves={dailyCurves}
                  currency={currency}
                  methodTypes={methodTypes}
                  hasMethods={hasMethods}
                  hasBaseCurve={hasBaseCurve}
                  date={curveDate}
                  productType={productType}
                />
              </>
            )}
          </COREContainer>
        </CORELoading>
      </Col>
      <Col
        xxl={shouldBeSingleColumn ? 24 : 10}
        xl={shouldBeSingleColumn ? 24 : 11}
        lg={24}
        md={24}
        sm={24}
        xs={24}
      >
        <CORELoading loading={isLoading} size={"lg"}>
          <BrokerChartContainer
            date={curveDate}
            productType={productType}
            productTypeName={productTypeName}
            dailyCurves={dailyCurves}
          />
        </CORELoading>
      </Col>
    </Row>
  );
};
