import { useLocalStorage } from "react-use";

type Key = string | number;

export const clearLocalStorageUntilLogout = [
  "greenMarketSnapshotChart",
  "greenMarketSnapshotZoom",
  "greenMarketProjectViewAs",
  "greenMarketProjectShowMap",
  "greenMarketProjectFilter",
  "greenMarketProjectCardPagination",
  "greenMarketProjectListPagination",
  "initiativeTable-filter",
  "portfolioOverviewTable-filter",
  "emissionsManagerInitiativeTableFilter",
  "emissionsManagerScenariosShowFromMyOrganisation",
  "emissionsManagerInitiativeCommentSortBy",
  "portfolioDetailOffsetsProductType",
  "portfolioFocus",
  "tradeTable",
  "tradeLogTable",
  "emissionsManagerOffsetTableFilter",
  "emissionsManagerAssignmentOffsetTableFilter",
  "emissionsManagerOffsetCommentSortBy",
  "tradelogTableFilter",
  "mainMenuOpenKeys",
  "mainMenuIsPanel",
  "counterpartiesTableFilter",
  "awayBrokerTableFilter",
  "dailycurvesEndOfDay",
  "dailycurvesCarbonTable",
  "UserTable",
  "GreenprojectTable",
  "GreenprojectTableFilter",
  "InitiativeTable",
  "EmissionScope1Table",
  "EmissionScope2Table",
  "EmissionScope3Table",
  "initiativeTable-pagination",
  "portfolioOverviewTable-pagination",
  "away-brokers-pagination",
  "counter-parties-pagination",
  "offset-assignments-table",
  "offset-blank-table",
  "db-activity",
  "locale",
  "emissionsManagerScenariosTable",
  "emissionsManagerScenariosOffsetTable",
  "createEditUserManagement",
  "emissionsManagerScenariosTableFilter",
  "emissionsManagerScenariosInitiativeTable",
  "companyManagementTable",
  "companyManagementFilters",
  "APITokenTable",
  "APITokenfilter",
  "addCompany",
  "userManagementTable",
  "userManagementFilter",
  "companyUserManagementTable",
  "companyUserManagementFilter",
  "analyticTable",
  "analyticFilter",
  "projectFinder",
  "subscriptionPackagesTable",
  "subscriptionPackagesTableFilter",
  "addSubscriptionPackages",
  "holdingsFocus",
  "currentHoldingsTableFilter",
  "researchAndAnalysisContent",
  "bespokeReportsContent",
  "publicationsTableFilter",
  "publicationsTable",
  "newsAndAlertsTable",
  "newsAndAlertsTableFilter",
  "insightNewsAndAlerts",
  "tradeTickerSiderFilter",
] as const;

export type LocalStorageUntilLogoutKey =
  typeof clearLocalStorageUntilLogout[number];

export const clearLocalStoragePerKey = (key: LocalStorageUntilLogoutKey) =>
  localStorage.removeItem(key);

export type UseLocalStorageUntilLogoutProps<T> = (props: {
  key: LocalStorageUntilLogoutKey;
  initialValue: T;
}) => [T, (input: ((prevState: T) => T) | T) => void];

export const useLocalStorageUntilLogout = <T>({
  key,
  initialValue,
}: Parameters<UseLocalStorageUntilLogoutProps<T>>[0]): ReturnType<
  UseLocalStorageUntilLogoutProps<T>
> => {
  const [curValue, setter] = useLocalStorage<T>(key, initialValue);
  const value: T = curValue ?? initialValue;
  const setValue: (input: ((prevState: T) => T) | T) => void = (
    input: ((prevState: T) => T) | T
  ) => {
    if (input instanceof Function) {
      return setter((curValue) => {
        const value = curValue ?? initialValue;
        return input(value);
      });
    }
    return setter(input);
  };
  return [value, setValue];
};

export const useLocalStorageUntilLogoutPerKey = <T>(
  storageKey: LocalStorageUntilLogoutKey,
  { key, initialValue }: { key: Key; initialValue: T }
): [T, (newValue: T) => void] => {
  const [storageValue, setStorageValue] = useLocalStorageUntilLogout<
    Record<Key, T>
  >({
    key: storageKey,
    initialValue: { [key]: initialValue },
  });

  const valueForKey = storageValue?.[key] ?? initialValue;

  const updateValue = (newValue: T) => {
    setStorageValue((prevStorageValue) => ({
      ...prevStorageValue,
      [key]: newValue,
    }));
  };

  return [valueForKey, updateValue];
};
