import React from "react";
import { COREDatePicker } from "../../../COREDesignSystem/Form/COREDatePicker";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREFormItem } from "../../../COREDesignSystem/Form/COREFormItem";
import { COREInput } from "../../../COREDesignSystem/Form/COREInput";
import { COREInputNumber } from "../../../COREDesignSystem/Form/COREInputNumber";
import { CORERadiobuttonGroup } from "../../../COREDesignSystem/Form/CORERadioButton";
import { CORESwitch } from "../../../COREDesignSystem/Form/CORESwitch";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";
import { BrokerSelect } from "./BrokerSelect";
import { ProductTreeSelect, ProductTreeSelectProps } from "./ProductTreeSelect";
import "./TradeTracker.less";
import { TradePartiesSelect } from "./TradePartiesSelect";
import { TestID, generateTestId } from "../../../shared/testids/testids";
import {
  CORESelect,
  CORESelectProps,
} from "../../../COREDesignSystem/Form/CORESelect";
import { COREInputCurrency } from "../../../COREDesignSystem/Form/COREInputCurrency";
import { Form, FormInstance } from "antd";
import { useAPIQueryRequest } from "../../../shared/customHoooks/useAPI";
import { TradableProducts as TradableProductsCommon } from "../../../openapi-typescript/common/tradable_products";
import { CurrencyName } from "../../../shared/CurrencySymbol";

export type TradableProducts = TradableProductsCommon;

export type TradeType = "firm" | "put" | "call";

type TradeTypeInputProps = {
  testID: TestID;
  onChange?: (value: TradeType) => void;
  value?: TradeType;
  tabIndex?: number;
};

const getTradeTypeFirmGridOrders = {
  price: 6,
  volume: 8,
  buyer: 10,
  seller: 12,
  broker: 14,
  rowNumber: 15,
  nonReported: 16,
  time: 7,
  settlementDate: 9,
  expiryDate: 11,
  strikePrice: 0,
  volatility: 0,
  notes: 13,
};

const getTradeTypeOptionGridOrders = {
  price: 6,
  volume: 8,
  buyer: 10,
  seller: 12,
  broker: 14,
  rowNumber: 16,
  nonReported: 18,
  time: 7,
  settlementDate: 9,
  expiryDate: 11,
  strikePrice: 13,
  volatility: 15,
  notes: 17,
};

export const TradeTypeInput: React.FC<
  TradeTypeInputProps & CORESelectProps
> = ({ onChange, value, testID, ...props }) => (
  <CORESelect
    mode={"basic"}
    onChange={onChange}
    value={value}
    size="lg"
    options={[
      { label: "Standard", value: "firm" },
      { label: "Option - Call", value: "call" },
      { label: "Option - Put", value: "put" },
    ]}
    testID={testID}
    {...props}
  />
);

const TradePriceInput: React.FC<{
  tabIndex: number;
  productTypeValue: number[] | null;
  testID: TestID;
}> = ({ tabIndex, productTypeValue, ...props }) => {
  const {
    data: { data: tradableProduct },
  }: {
    sync: boolean;
    loading: boolean;
    data: { data: TradableProducts | undefined };
  } = useAPIQueryRequest(
    "tradableProduct",
    { id: `eq.${productTypeValue}` },
    !productTypeValue || productTypeValue.length === 0
  );

  const currency =
    (tradableProduct?.productTypes?.currency as CurrencyName) ?? "AUD";

  return (
    <COREInputCurrency
      placeholder="0.00"
      widthSize="sm"
      type="number"
      size="lg"
      currency={currency}
      autoComplete="off"
      min={0}
      precision={2}
      tabIndex={tabIndex}
      {...props}
    />
  );
};

const ProductSelector: React.FC<{
  tabIndex: number;
  typeValue: ProductTreeSelectProps["type"];
  stripValue: ProductTreeSelectProps["isStrip"];
  isStrip: ProductTreeSelectProps["isStrip"];
  multiple: ProductTreeSelectProps["isStrip"];
  value?: ProductTreeSelectProps["values"];
  placeholder?: ProductTreeSelectProps["placeholder"];
}> = ({ tabIndex, typeValue, value: values, ...props }) => {
  return (
    <ProductTreeSelect
      testID={generateTestId("tradetracker", "product")}
      type={typeValue}
      tradeType="firm"
      isASXTraded={false}
      search={true}
      tabIndex={tabIndex}
      values={values ?? []}
      mode="input"
      {...props}
    />
  );
};

export const NewTradeFormItem: React.FC<{
  form: FormInstance;
}> = ({ form }) => (
  <Form.List name="rerender">
    {(_, { remove: triggerRerender }) => {
      const typeValue =
        form.getFieldValue(["rerender", 0, "type"]) ?? "generic";
      const tradeType = form.getFieldValue(["rerender", 0, "tradeType"]);
      const isFirmTradeType = tradeType === "firm";
      const stripValue = form.getFieldValue(["rerender", 0, "strip"]);
      const priceLabel = isFirmTradeType ? "Price" : "Price (premium)";
      const gridOrders = isFirmTradeType
        ? getTradeTypeFirmGridOrders
        : getTradeTypeOptionGridOrders;
      const productTypeValue = form.getFieldValue(["rerender", 0, typeValue]);

      return (
        <>
          <COREFormItem
            normalize={(v) => {
              triggerRerender(1);
              return v;
            }}
            name={[0, "strip"]}
            gridItemColumn={{
              sm: "2 / 5",
            }}
            testID={generateTestId("tradetracker", "strip-formitem")}
            label={
              <CORETypographyInput type="label">Strip</CORETypographyInput>
            }
            valuePropName="checked"
            gridOrder={{
              xxs: "unset",
              xl: 1,
            }}
          >
            <CORESwitch
              testID={generateTestId("tradetracker", "strip-input")}
              defaultChecked={false}
              tabIndex={1}
            />
          </COREFormItem>

          <COREFormItem
            normalize={(v) => {
              triggerRerender(1);
              return v;
            }}
            name={[0, "type"]}
            gridItemColumn={{
              sm: "2 / 5",
            }}
            testID={generateTestId("tradetracker", "type-formitem")}
            label={<CORETypographyInput type="label">Type</CORETypographyInput>}
            initialValue="generic"
            gridOrder={{
              xxs: "unset",
              xl: 2,
            }}
          >
            <CORERadiobuttonGroup
              size="large"
              optionType="button"
              buttonStyle="solid"
              testID={generateTestId("tradetracker", "type-input")}
              tabIndex={2}
              radioBtnValues={[
                {
                  name: "GENERIC",
                  value: "generic",
                  testID: "tradetracker-generic-button",
                },
                {
                  name: "PROJECT",
                  value: "project",
                  testID: "tradetracker-project-button",
                },
              ]}
            />
          </COREFormItem>

          <COREFormItem
            normalize={(v) => {
              triggerRerender(1);
              return v;
            }}
            name={[0, "tradeType"]}
            required
            testID={generateTestId("tradetracker", "trade-type-form-item")}
            label={
              <CORETypographyInput
                testID={generateTestId("tradetracker", "trade-type-form-label")}
                type="label"
              >
                Trade type
              </CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
            }}
            gridOrder={{
              xxs: "unset",
              xl: 3,
            }}
          >
            <TradeTypeInput
              testID={generateTestId("tradetracker", "trade-type-input")}
              tabIndex={3}
            />
          </COREFormItem>

          <COREFormItem
            normalize={(v): CORESelectProps["options"] => {
              if (!v) return [];
              if (!v.length) return [v];
              return v;
            }}
            name={[0, typeValue]}
            gridItemColumn={{
              sm: "2 / 5",
            }}
            testID={generateTestId("tradetracker", "product-formitem")}
            label={
              <CORETypographyInput type="label">Product</CORETypographyInput>
            }
            rules={[{ required: true, message: "Product must have a value" }]}
            gridOrder={{
              xxs: "unset",
              xl: 4,
            }}
          >
            <ProductSelector
              typeValue={typeValue}
              isStrip={Boolean(stripValue)}
              multiple={stripValue}
              stripValue={false}
              placeholder={
                stripValue ? "Select product codes" : "Select product code"
              }
              tabIndex={4}
            />
          </COREFormItem>
          <COREFormItem
            testID={generateTestId("tradetracker", "divider-1-style")}
            gridItemColumn={{
              sm: "1 / 5",
            }}
            gridOrder={{
              xxs: "unset",
              xl: 5,
            }}
          >
            <COREDivider />
          </COREFormItem>

          <COREFormItem
            name={[0, "price"]}
            testID={generateTestId("tradetracker", "price-formitem")}
            label={
              <CORETypographyInput type="label">
                {priceLabel}
              </CORETypographyInput>
            }
            rules={[{ required: true, message: "Price must have a value" }]}
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["price"],
            }}
          >
            <TradePriceInput
              tabIndex={5}
              productTypeValue={productTypeValue}
              testID={generateTestId("tradetracker", "price-input")}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "volume"]}
            testID={generateTestId("tradetracker", "quantity-formitem")}
            label={
              <CORETypographyInput type="label">Volume</CORETypographyInput>
            }
            rules={[{ required: true, message: "Volume must have a value" }]}
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["volume"],
            }}
          >
            <COREInputNumber
              widthSize="md"
              testID={generateTestId("tradetracker", "quantity-input")}
              placeholder="0"
              size="lg"
              tabIndex={6}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "buyer"]}
            testID={generateTestId("tradetracker", "buyer")}
            label={
              <CORETypographyInput type="label">Buyer</CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["buyer"],
            }}
          >
            <TradePartiesSelect
              placeholder="Buyer name"
              testID={generateTestId(
                "tradetracker",
                "TradePartiesSelect-buyer-input"
              )}
              tabIndex={7}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "seller"]}
            testID={generateTestId("tradetracker", "seller")}
            label={
              <CORETypographyInput type="label">Seller</CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["seller"],
            }}
          >
            <TradePartiesSelect
              placeholder="Seller name"
              testID={generateTestId(
                "tradetracker",
                "TradePartiesSelect-seller-input"
              )}
              tabIndex={8}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "broker"]}
            testID={generateTestId("tradetracker", "broker")}
            label={
              <CORETypographyInput type="label">
                Away broker
              </CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: isFirmTradeType ? "2 / 4" : "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["broker"],
            }}
          >
            <BrokerSelect
              placeholder="Broker name"
              testID={generateTestId(
                "tradetracker",
                "TradePartiesSelect-broker-input"
              )}
              tabIndex={9}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "rowNumber"]}
            testID={generateTestId("tradetracker", "row-number-formitem")}
            label={
              <CORETypographyInput type="label">Row number</CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: isFirmTradeType ? "2 / 5" : "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["rowNumber"],
            }}
          >
            <COREInputNumber
              widthSize="sm"
              size="lg"
              testID={generateTestId("tradetracker", "row-number-input")}
              placeholder="0"
              button
              tabIndex={10}
            />
          </COREFormItem>
          <COREFormItem
            className={"align-center"}
            name={[0, "nonReported"]}
            valuePropName="checked"
            testID={generateTestId("tradetracker", "non-reported-formitem")}
            label={
              <CORETypographyInput type="label">
                Non-reported
              </CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: isFirmTradeType ? "2 / 5" : "2 / 4",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["nonReported"],
            }}
          >
            <CORESwitch
              testID={generateTestId("tradetracker", "non-reported-input")}
              tabIndex={11}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "time"]}
            testID={generateTestId("tradetracker", "trade-date-formitem")}
            label={
              <CORETypographyInput type="label">Trade date</CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["time"],
            }}
          >
            <COREDatePicker
              widthSize="md"
              testID={generateTestId("tradetracker", "trade-date-input")}
              placeholder="Current date"
              size="lg"
              tabIndex={12}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "settlementDate"]}
            testID={generateTestId("tradetracker", "term-date-formitem")}
            label={
              <CORETypographyInput type="label">Term date</CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["settlementDate"],
            }}
          >
            <COREDatePicker
              widthSize="md"
              testID={generateTestId("tradetracker", "term-date-input")}
              placeholder="Term date"
              size="lg"
              tabIndex={13}
            />
          </COREFormItem>

          <COREFormItem
            name={[0, "expiryDate"]}
            testID={generateTestId("tradetracker", "expiry-date-formitem")}
            label={
              <CORETypographyInput type="label">
                Expiry date
              </CORETypographyInput>
            }
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["expiryDate"],
            }}
            rules={[
              {
                required: !isFirmTradeType,
                message: "Expiry date must have a value",
              },
            ]}
          >
            <COREDatePicker
              widthSize="md"
              testID={generateTestId("tradetracker", "expiry-date-input")}
              placeholder="Expiry date"
              size="lg"
              tabIndex={14}
            />
          </COREFormItem>

          {!isFirmTradeType && (
            <>
              <COREFormItem
                name={[0, "strikePrice"]}
                testID={generateTestId("tradetracker", "strike-price-formitem")}
                label={
                  <CORETypographyInput
                    testID={generateTestId(
                      "tradetracker",
                      "strike-price-label"
                    )}
                    type="label"
                  >
                    Strike price
                  </CORETypographyInput>
                }
                gridItemColumn={{
                  sm: "2 / 5",
                  xl: "unset",
                }}
                gridOrder={{
                  xxs: "unset",
                  xl: gridOrders["strikePrice"],
                }}
                rules={[
                  {
                    required: true,
                    message: "Strike price must have a value",
                  },
                ]}
              >
                <TradePriceInput
                  tabIndex={15}
                  productTypeValue={productTypeValue}
                  testID={generateTestId("tradetracker", "strike-price-input")}
                />
              </COREFormItem>

              <COREFormItem
                name={[0, "volatility"]}
                testID={generateTestId("tradetracker", "volatility-formitem")}
                label={
                  <CORETypographyInput type="label">
                    Volatility
                  </CORETypographyInput>
                }
                gridItemColumn={{
                  sm: "2 / 5",
                  xl: "unset",
                }}
                gridOrder={{
                  xxs: "unset",
                  xl: gridOrders["volatility"],
                }}
              >
                <COREInputNumber
                  size="lg"
                  widthSize="sm"
                  autoComplete="off"
                  placeholder="0.00"
                  addonAfter="%"
                  precision={2}
                  testID={generateTestId("tradetracker", "volatility-input")}
                  min={0}
                  tabIndex={16}
                />
              </COREFormItem>
            </>
          )}

          <COREFormItem
            name={[0, "notes"]}
            testID={generateTestId("tradetracker", "note-formitem")}
            label={<CORETypographyInput type="label">Note</CORETypographyInput>}
            gridItemColumn={{
              sm: "2 / 5",
              xl: "unset",
            }}
            gridItemRow={{
              xl: "span 2",
            }}
            gridOrder={{
              xxs: "unset",
              xl: gridOrders["notes"],
            }}
            labelSize="lg"
          >
            <COREInput.TextArea
              widthSize="xxl"
              testID={generateTestId("tradetracker", "note-input")}
              allowResize={false}
              placeholder="Add a note"
              style={{ height: "96px" }}
              tabIndex={17}
            />
          </COREFormItem>
        </>
      );
    }}
  </Form.List>
);
